import { default as bankeNCgcNqEhaMeta } from "/home/app/agent-snap/pages/bank.vue?macro=true";
import { default as catalogsxnBTWrt5dAMeta } from "/home/app/agent-snap/pages/catalogs.vue?macro=true";
import { default as create_45accountQ0KVkzX4KbMeta } from "/home/app/base/pages/create-account.vue?macro=true";
import { default as create_45beneficialDunt33mlqsMeta } from "/home/app/base/pages/create-beneficial.vue?macro=true";
import { default as forget_45passworduhkNlHfCybMeta } from "/home/app/base/pages/forget-password.vue?macro=true";
import { default as indexvvMKtCBMXiMeta } from "/home/app/agent-snap/pages/index.vue?macro=true";
import { default as invoice_45payment_45methodQNRJVYcswYMeta } from "/home/app/agent-snap/pages/invoice-payment-method.vue?macro=true";
import { default as invoicesagWxOSeNLQMeta } from "/home/app/agent-snap/pages/invoices.vue?macro=true";
import { default as login8Iabc9EEkwMeta } from "/home/app/agent-snap/pages/login.vue?macro=true";
import { default as my_45preferencefIiTjOE5GTMeta } from "/home/app/agent-snap/pages/my-preference.vue?macro=true";
import { default as indexdJ5wGarLlzMeta } from "/home/app/base/pages/new-sign-up/account-info/index.vue?macro=true";
import { default as indexVNRxbr7a02Meta } from "/home/app/base/pages/new-sign-up/approve/index.vue?macro=true";
import { default as indexMQZy2eVaPNMeta } from "/home/app/base/pages/new-sign-up/bank-info/index.vue?macro=true";
import { default as indexKeKtb8k6UcMeta } from "/home/app/base/pages/new-sign-up/beneficial-owners-info/index.vue?macro=true";
import { default as indexKkpBrXk8dCMeta } from "/home/app/base/pages/new-sign-up/business-info/index.vue?macro=true";
import { default as indexNEAscAfBhWMeta } from "/home/app/base/pages/new-sign-up/controller-info/index.vue?macro=true";
import { default as indexhTQC1Hr2OzMeta } from "/home/app/base/pages/new-sign-up/document/index.vue?macro=true";
import { default as index0qTNVheK5ZMeta } from "/home/app/base/pages/new-sign-up/identity-info/index.vue?macro=true";
import { default as indexj2MQ8NcgqGMeta } from "/home/app/base/pages/new-sign-up/index.vue?macro=true";
import { default as indexdUYICc9YTtMeta } from "/home/app/base/pages/new-sign-up/location-info/index.vue?macro=true";
import { default as indexj22Ua7VuJ0Meta } from "/home/app/base/pages/new-sign-up/paper-check-info/index.vue?macro=true";
import { default as indexnm5tgI6YcaMeta } from "/home/app/base/pages/new-sign-up/retry/business-sole-proprietorship/index.vue?macro=true";
import { default as indexfnfkUOEaQhMeta } from "/home/app/base/pages/new-sign-up/retry/business/index.vue?macro=true";
import { default as indexACc6pXkK8GMeta } from "/home/app/base/pages/new-sign-up/retry/personal/index.vue?macro=true";
import { default as indexVdemZctNohMeta } from "/home/app/base/pages/new-sign-up/verification-in-progress/index.vue?macro=true";
import { default as _91id_93jFmKA9tgySMeta } from "/home/app/base/pages/pending-payment/[id].vue?macro=true";
import { default as indexPuFUNP1c7kMeta } from "/home/app/base/pages/pending-payment/index.vue?macro=true";
import { default as pending_45paymentp9lSRUDOqEMeta } from "/home/app/base/pages/pending-payment.vue?macro=true";
import { default as profilenHeABq62pEMeta } from "/home/app/agent-snap/pages/profile.vue?macro=true";
import { default as reset_45password9ZA46tEuENMeta } from "/home/app/base/pages/reset-password.vue?macro=true";
import { default as select_45appeHptlIFxSbMeta } from "/home/app/base/pages/select-app.vue?macro=true";
import { default as sign_45up_45senderIAwp76XWFIMeta } from "/home/app/agent-snap/pages/sign-up-sender.vue?macro=true";
import { default as sign_45up_45steps6Ui2hXrVlUMeta } from "/home/app/base/pages/sign-up-steps.vue?macro=true";
import { default as sign_45upJB7A87BZ6AMeta } from "/home/app/agent-snap/pages/sign-up.vue?macro=true";
import { default as _91id_93tM0wqQPKVgMeta } from "/home/app/agent-snap/pages/sign/[id].vue?macro=true";
import { default as index9CsQ8CJTSZMeta } from "/home/app/agent-snap/pages/sign/index.vue?macro=true";
import { default as signaturesYWrA88POnBMeta } from "/home/app/agent-snap/pages/signatures.vue?macro=true";
import { default as signinghT4DOgz9ArMeta } from "/home/app/agent-snap/pages/signing.vue?macro=true";
import { default as SnapRefundPrivacyPolicy1NFZhYtP8SMeta } from "/home/app/base/pages/SnapRefundPrivacyPolicy.vue?macro=true";
import { default as SnapRefundToSXVQkJ4YwikMeta } from "/home/app/base/pages/SnapRefundToS.vue?macro=true";
import { default as transactionsMdK1uuXTpIMeta } from "/home/app/base/pages/transactions.vue?macro=true";
import { default as upload_45documentbESsoqES6gMeta } from "/home/app/base/pages/upload-document.vue?macro=true";
export default [
  {
    name: bankeNCgcNqEhaMeta?.name ?? "bank",
    path: bankeNCgcNqEhaMeta?.path ?? "/bank",
    meta: bankeNCgcNqEhaMeta || {},
    alias: bankeNCgcNqEhaMeta?.alias || [],
    redirect: bankeNCgcNqEhaMeta?.redirect,
    component: () => import("/home/app/agent-snap/pages/bank.vue").then(m => m.default || m)
  },
  {
    name: catalogsxnBTWrt5dAMeta?.name ?? "catalogs",
    path: catalogsxnBTWrt5dAMeta?.path ?? "/catalogs",
    meta: catalogsxnBTWrt5dAMeta || {},
    alias: catalogsxnBTWrt5dAMeta?.alias || [],
    redirect: catalogsxnBTWrt5dAMeta?.redirect,
    component: () => import("/home/app/agent-snap/pages/catalogs.vue").then(m => m.default || m)
  },
  {
    name: create_45accountQ0KVkzX4KbMeta?.name ?? "create-account",
    path: create_45accountQ0KVkzX4KbMeta?.path ?? "/create-account",
    meta: create_45accountQ0KVkzX4KbMeta || {},
    alias: create_45accountQ0KVkzX4KbMeta?.alias || [],
    redirect: create_45accountQ0KVkzX4KbMeta?.redirect,
    component: () => import("/home/app/base/pages/create-account.vue").then(m => m.default || m)
  },
  {
    name: create_45beneficialDunt33mlqsMeta?.name ?? "create-beneficial",
    path: create_45beneficialDunt33mlqsMeta?.path ?? "/create-beneficial",
    meta: create_45beneficialDunt33mlqsMeta || {},
    alias: create_45beneficialDunt33mlqsMeta?.alias || [],
    redirect: create_45beneficialDunt33mlqsMeta?.redirect,
    component: () => import("/home/app/base/pages/create-beneficial.vue").then(m => m.default || m)
  },
  {
    name: forget_45passworduhkNlHfCybMeta?.name ?? "forget-password",
    path: forget_45passworduhkNlHfCybMeta?.path ?? "/forget-password",
    meta: forget_45passworduhkNlHfCybMeta || {},
    alias: forget_45passworduhkNlHfCybMeta?.alias || [],
    redirect: forget_45passworduhkNlHfCybMeta?.redirect,
    component: () => import("/home/app/base/pages/forget-password.vue").then(m => m.default || m)
  },
  {
    name: indexvvMKtCBMXiMeta?.name ?? "index",
    path: indexvvMKtCBMXiMeta?.path ?? "/",
    meta: indexvvMKtCBMXiMeta || {},
    alias: indexvvMKtCBMXiMeta?.alias || [],
    redirect: indexvvMKtCBMXiMeta?.redirect,
    component: () => import("/home/app/agent-snap/pages/index.vue").then(m => m.default || m)
  },
  {
    name: invoice_45payment_45methodQNRJVYcswYMeta?.name ?? "invoice-payment-method",
    path: invoice_45payment_45methodQNRJVYcswYMeta?.path ?? "/invoice-payment-method",
    meta: invoice_45payment_45methodQNRJVYcswYMeta || {},
    alias: invoice_45payment_45methodQNRJVYcswYMeta?.alias || [],
    redirect: invoice_45payment_45methodQNRJVYcswYMeta?.redirect,
    component: () => import("/home/app/agent-snap/pages/invoice-payment-method.vue").then(m => m.default || m)
  },
  {
    name: invoicesagWxOSeNLQMeta?.name ?? "invoices",
    path: invoicesagWxOSeNLQMeta?.path ?? "/invoices",
    meta: invoicesagWxOSeNLQMeta || {},
    alias: invoicesagWxOSeNLQMeta?.alias || [],
    redirect: invoicesagWxOSeNLQMeta?.redirect,
    component: () => import("/home/app/agent-snap/pages/invoices.vue").then(m => m.default || m)
  },
  {
    name: login8Iabc9EEkwMeta?.name ?? "login",
    path: login8Iabc9EEkwMeta?.path ?? "/login",
    meta: login8Iabc9EEkwMeta || {},
    alias: login8Iabc9EEkwMeta?.alias || [],
    redirect: login8Iabc9EEkwMeta?.redirect,
    component: () => import("/home/app/agent-snap/pages/login.vue").then(m => m.default || m)
  },
  {
    name: my_45preferencefIiTjOE5GTMeta?.name ?? "my-preference",
    path: my_45preferencefIiTjOE5GTMeta?.path ?? "/my-preference",
    meta: my_45preferencefIiTjOE5GTMeta || {},
    alias: my_45preferencefIiTjOE5GTMeta?.alias || [],
    redirect: my_45preferencefIiTjOE5GTMeta?.redirect,
    component: () => import("/home/app/agent-snap/pages/my-preference.vue").then(m => m.default || m)
  },
  {
    name: indexdJ5wGarLlzMeta?.name ?? "new-sign-up-account-info",
    path: indexdJ5wGarLlzMeta?.path ?? "/new-sign-up/account-info",
    meta: indexdJ5wGarLlzMeta || {},
    alias: indexdJ5wGarLlzMeta?.alias || [],
    redirect: indexdJ5wGarLlzMeta?.redirect,
    component: () => import("/home/app/base/pages/new-sign-up/account-info/index.vue").then(m => m.default || m)
  },
  {
    name: indexVNRxbr7a02Meta?.name ?? "new-sign-up-approve",
    path: indexVNRxbr7a02Meta?.path ?? "/new-sign-up/approve",
    meta: indexVNRxbr7a02Meta || {},
    alias: indexVNRxbr7a02Meta?.alias || [],
    redirect: indexVNRxbr7a02Meta?.redirect,
    component: () => import("/home/app/base/pages/new-sign-up/approve/index.vue").then(m => m.default || m)
  },
  {
    name: indexMQZy2eVaPNMeta?.name ?? "new-sign-up-bank-info",
    path: indexMQZy2eVaPNMeta?.path ?? "/new-sign-up/bank-info",
    meta: indexMQZy2eVaPNMeta || {},
    alias: indexMQZy2eVaPNMeta?.alias || [],
    redirect: indexMQZy2eVaPNMeta?.redirect,
    component: () => import("/home/app/base/pages/new-sign-up/bank-info/index.vue").then(m => m.default || m)
  },
  {
    name: indexKeKtb8k6UcMeta?.name ?? "new-sign-up-beneficial-owners-info",
    path: indexKeKtb8k6UcMeta?.path ?? "/new-sign-up/beneficial-owners-info",
    meta: indexKeKtb8k6UcMeta || {},
    alias: indexKeKtb8k6UcMeta?.alias || [],
    redirect: indexKeKtb8k6UcMeta?.redirect,
    component: () => import("/home/app/base/pages/new-sign-up/beneficial-owners-info/index.vue").then(m => m.default || m)
  },
  {
    name: indexKkpBrXk8dCMeta?.name ?? "new-sign-up-business-info",
    path: indexKkpBrXk8dCMeta?.path ?? "/new-sign-up/business-info",
    meta: indexKkpBrXk8dCMeta || {},
    alias: indexKkpBrXk8dCMeta?.alias || [],
    redirect: indexKkpBrXk8dCMeta?.redirect,
    component: () => import("/home/app/base/pages/new-sign-up/business-info/index.vue").then(m => m.default || m)
  },
  {
    name: indexNEAscAfBhWMeta?.name ?? "new-sign-up-controller-info",
    path: indexNEAscAfBhWMeta?.path ?? "/new-sign-up/controller-info",
    meta: indexNEAscAfBhWMeta || {},
    alias: indexNEAscAfBhWMeta?.alias || [],
    redirect: indexNEAscAfBhWMeta?.redirect,
    component: () => import("/home/app/base/pages/new-sign-up/controller-info/index.vue").then(m => m.default || m)
  },
  {
    name: indexhTQC1Hr2OzMeta?.name ?? "new-sign-up-document",
    path: indexhTQC1Hr2OzMeta?.path ?? "/new-sign-up/document",
    meta: indexhTQC1Hr2OzMeta || {},
    alias: indexhTQC1Hr2OzMeta?.alias || [],
    redirect: indexhTQC1Hr2OzMeta?.redirect,
    component: () => import("/home/app/base/pages/new-sign-up/document/index.vue").then(m => m.default || m)
  },
  {
    name: index0qTNVheK5ZMeta?.name ?? "new-sign-up-identity-info",
    path: index0qTNVheK5ZMeta?.path ?? "/new-sign-up/identity-info",
    meta: index0qTNVheK5ZMeta || {},
    alias: index0qTNVheK5ZMeta?.alias || [],
    redirect: index0qTNVheK5ZMeta?.redirect,
    component: () => import("/home/app/base/pages/new-sign-up/identity-info/index.vue").then(m => m.default || m)
  },
  {
    name: indexj2MQ8NcgqGMeta?.name ?? "new-sign-up",
    path: indexj2MQ8NcgqGMeta?.path ?? "/new-sign-up",
    meta: indexj2MQ8NcgqGMeta || {},
    alias: indexj2MQ8NcgqGMeta?.alias || [],
    redirect: indexj2MQ8NcgqGMeta?.redirect,
    component: () => import("/home/app/base/pages/new-sign-up/index.vue").then(m => m.default || m)
  },
  {
    name: indexdUYICc9YTtMeta?.name ?? "new-sign-up-location-info",
    path: indexdUYICc9YTtMeta?.path ?? "/new-sign-up/location-info",
    meta: indexdUYICc9YTtMeta || {},
    alias: indexdUYICc9YTtMeta?.alias || [],
    redirect: indexdUYICc9YTtMeta?.redirect,
    component: () => import("/home/app/base/pages/new-sign-up/location-info/index.vue").then(m => m.default || m)
  },
  {
    name: indexj22Ua7VuJ0Meta?.name ?? "new-sign-up-paper-check-info",
    path: indexj22Ua7VuJ0Meta?.path ?? "/new-sign-up/paper-check-info",
    meta: indexj22Ua7VuJ0Meta || {},
    alias: indexj22Ua7VuJ0Meta?.alias || [],
    redirect: indexj22Ua7VuJ0Meta?.redirect,
    component: () => import("/home/app/base/pages/new-sign-up/paper-check-info/index.vue").then(m => m.default || m)
  },
  {
    name: indexnm5tgI6YcaMeta?.name ?? "new-sign-up-retry-business-sole-proprietorship",
    path: indexnm5tgI6YcaMeta?.path ?? "/new-sign-up/retry/business-sole-proprietorship",
    meta: indexnm5tgI6YcaMeta || {},
    alias: indexnm5tgI6YcaMeta?.alias || [],
    redirect: indexnm5tgI6YcaMeta?.redirect,
    component: () => import("/home/app/base/pages/new-sign-up/retry/business-sole-proprietorship/index.vue").then(m => m.default || m)
  },
  {
    name: indexfnfkUOEaQhMeta?.name ?? "new-sign-up-retry-business",
    path: indexfnfkUOEaQhMeta?.path ?? "/new-sign-up/retry/business",
    meta: indexfnfkUOEaQhMeta || {},
    alias: indexfnfkUOEaQhMeta?.alias || [],
    redirect: indexfnfkUOEaQhMeta?.redirect,
    component: () => import("/home/app/base/pages/new-sign-up/retry/business/index.vue").then(m => m.default || m)
  },
  {
    name: indexACc6pXkK8GMeta?.name ?? "new-sign-up-retry-personal",
    path: indexACc6pXkK8GMeta?.path ?? "/new-sign-up/retry/personal",
    meta: indexACc6pXkK8GMeta || {},
    alias: indexACc6pXkK8GMeta?.alias || [],
    redirect: indexACc6pXkK8GMeta?.redirect,
    component: () => import("/home/app/base/pages/new-sign-up/retry/personal/index.vue").then(m => m.default || m)
  },
  {
    name: indexVdemZctNohMeta?.name ?? "new-sign-up-verification-in-progress",
    path: indexVdemZctNohMeta?.path ?? "/new-sign-up/verification-in-progress",
    meta: indexVdemZctNohMeta || {},
    alias: indexVdemZctNohMeta?.alias || [],
    redirect: indexVdemZctNohMeta?.redirect,
    component: () => import("/home/app/base/pages/new-sign-up/verification-in-progress/index.vue").then(m => m.default || m)
  },
  {
    name: pending_45paymentp9lSRUDOqEMeta?.name ?? undefined,
    path: pending_45paymentp9lSRUDOqEMeta?.path ?? "/pending-payment",
    meta: pending_45paymentp9lSRUDOqEMeta || {},
    alias: pending_45paymentp9lSRUDOqEMeta?.alias || [],
    redirect: pending_45paymentp9lSRUDOqEMeta?.redirect,
    component: () => import("/home/app/base/pages/pending-payment.vue").then(m => m.default || m),
    children: [
  {
    name: _91id_93jFmKA9tgySMeta?.name ?? "pending-payment-id",
    path: _91id_93jFmKA9tgySMeta?.path ?? ":id()",
    meta: _91id_93jFmKA9tgySMeta || {},
    alias: _91id_93jFmKA9tgySMeta?.alias || [],
    redirect: _91id_93jFmKA9tgySMeta?.redirect,
    component: () => import("/home/app/base/pages/pending-payment/[id].vue").then(m => m.default || m)
  },
  {
    name: indexPuFUNP1c7kMeta?.name ?? "pending-payment",
    path: indexPuFUNP1c7kMeta?.path ?? "",
    meta: indexPuFUNP1c7kMeta || {},
    alias: indexPuFUNP1c7kMeta?.alias || [],
    redirect: indexPuFUNP1c7kMeta?.redirect,
    component: () => import("/home/app/base/pages/pending-payment/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: profilenHeABq62pEMeta?.name ?? "profile",
    path: profilenHeABq62pEMeta?.path ?? "/profile",
    meta: profilenHeABq62pEMeta || {},
    alias: profilenHeABq62pEMeta?.alias || [],
    redirect: profilenHeABq62pEMeta?.redirect,
    component: () => import("/home/app/agent-snap/pages/profile.vue").then(m => m.default || m)
  },
  {
    name: reset_45password9ZA46tEuENMeta?.name ?? "reset-password",
    path: reset_45password9ZA46tEuENMeta?.path ?? "/reset-password",
    meta: reset_45password9ZA46tEuENMeta || {},
    alias: reset_45password9ZA46tEuENMeta?.alias || [],
    redirect: reset_45password9ZA46tEuENMeta?.redirect,
    component: () => import("/home/app/base/pages/reset-password.vue").then(m => m.default || m)
  },
  {
    name: select_45appeHptlIFxSbMeta?.name ?? "select-app",
    path: select_45appeHptlIFxSbMeta?.path ?? "/select-app",
    meta: select_45appeHptlIFxSbMeta || {},
    alias: select_45appeHptlIFxSbMeta?.alias || [],
    redirect: select_45appeHptlIFxSbMeta?.redirect,
    component: () => import("/home/app/base/pages/select-app.vue").then(m => m.default || m)
  },
  {
    name: sign_45up_45senderIAwp76XWFIMeta?.name ?? "sign-up-sender",
    path: sign_45up_45senderIAwp76XWFIMeta?.path ?? "/sign-up-sender",
    meta: sign_45up_45senderIAwp76XWFIMeta || {},
    alias: sign_45up_45senderIAwp76XWFIMeta?.alias || [],
    redirect: sign_45up_45senderIAwp76XWFIMeta?.redirect,
    component: () => import("/home/app/agent-snap/pages/sign-up-sender.vue").then(m => m.default || m)
  },
  {
    name: sign_45up_45steps6Ui2hXrVlUMeta?.name ?? "sign-up-steps",
    path: sign_45up_45steps6Ui2hXrVlUMeta?.path ?? "/sign-up-steps",
    meta: sign_45up_45steps6Ui2hXrVlUMeta || {},
    alias: sign_45up_45steps6Ui2hXrVlUMeta?.alias || [],
    redirect: sign_45up_45steps6Ui2hXrVlUMeta?.redirect,
    component: () => import("/home/app/base/pages/sign-up-steps.vue").then(m => m.default || m)
  },
  {
    name: sign_45upJB7A87BZ6AMeta?.name ?? "sign-up",
    path: sign_45upJB7A87BZ6AMeta?.path ?? "/sign-up",
    meta: sign_45upJB7A87BZ6AMeta || {},
    alias: sign_45upJB7A87BZ6AMeta?.alias || [],
    redirect: sign_45upJB7A87BZ6AMeta?.redirect,
    component: () => import("/home/app/agent-snap/pages/sign-up.vue").then(m => m.default || m)
  },
  {
    name: _91id_93tM0wqQPKVgMeta?.name ?? "sign-id",
    path: _91id_93tM0wqQPKVgMeta?.path ?? "/sign/:id()",
    meta: _91id_93tM0wqQPKVgMeta || {},
    alias: _91id_93tM0wqQPKVgMeta?.alias || [],
    redirect: _91id_93tM0wqQPKVgMeta?.redirect,
    component: () => import("/home/app/agent-snap/pages/sign/[id].vue").then(m => m.default || m)
  },
  {
    name: index9CsQ8CJTSZMeta?.name ?? "sign",
    path: index9CsQ8CJTSZMeta?.path ?? "/sign",
    meta: index9CsQ8CJTSZMeta || {},
    alias: index9CsQ8CJTSZMeta?.alias || [],
    redirect: index9CsQ8CJTSZMeta?.redirect,
    component: () => import("/home/app/agent-snap/pages/sign/index.vue").then(m => m.default || m)
  },
  {
    name: signaturesYWrA88POnBMeta?.name ?? "signatures",
    path: signaturesYWrA88POnBMeta?.path ?? "/signatures",
    meta: signaturesYWrA88POnBMeta || {},
    alias: signaturesYWrA88POnBMeta?.alias || [],
    redirect: signaturesYWrA88POnBMeta?.redirect,
    component: () => import("/home/app/agent-snap/pages/signatures.vue").then(m => m.default || m)
  },
  {
    name: signinghT4DOgz9ArMeta?.name ?? "signing",
    path: signinghT4DOgz9ArMeta?.path ?? "/signing",
    meta: signinghT4DOgz9ArMeta || {},
    alias: signinghT4DOgz9ArMeta?.alias || [],
    redirect: signinghT4DOgz9ArMeta?.redirect,
    component: () => import("/home/app/agent-snap/pages/signing.vue").then(m => m.default || m)
  },
  {
    name: SnapRefundPrivacyPolicy1NFZhYtP8SMeta?.name ?? "SnapRefundPrivacyPolicy",
    path: SnapRefundPrivacyPolicy1NFZhYtP8SMeta?.path ?? "/SnapRefundPrivacyPolicy",
    meta: SnapRefundPrivacyPolicy1NFZhYtP8SMeta || {},
    alias: SnapRefundPrivacyPolicy1NFZhYtP8SMeta?.alias || [],
    redirect: SnapRefundPrivacyPolicy1NFZhYtP8SMeta?.redirect,
    component: () => import("/home/app/base/pages/SnapRefundPrivacyPolicy.vue").then(m => m.default || m)
  },
  {
    name: SnapRefundToSXVQkJ4YwikMeta?.name ?? "SnapRefundToS",
    path: SnapRefundToSXVQkJ4YwikMeta?.path ?? "/SnapRefundToS",
    meta: SnapRefundToSXVQkJ4YwikMeta || {},
    alias: SnapRefundToSXVQkJ4YwikMeta?.alias || [],
    redirect: SnapRefundToSXVQkJ4YwikMeta?.redirect,
    component: () => import("/home/app/base/pages/SnapRefundToS.vue").then(m => m.default || m)
  },
  {
    name: transactionsMdK1uuXTpIMeta?.name ?? "transactions",
    path: transactionsMdK1uuXTpIMeta?.path ?? "/transactions",
    meta: transactionsMdK1uuXTpIMeta || {},
    alias: transactionsMdK1uuXTpIMeta?.alias || [],
    redirect: transactionsMdK1uuXTpIMeta?.redirect,
    component: () => import("/home/app/base/pages/transactions.vue").then(m => m.default || m)
  },
  {
    name: upload_45documentbESsoqES6gMeta?.name ?? "upload-document",
    path: upload_45documentbESsoqES6gMeta?.path ?? "/upload-document",
    meta: upload_45documentbESsoqES6gMeta || {},
    alias: upload_45documentbESsoqES6gMeta?.alias || [],
    redirect: upload_45documentbESsoqES6gMeta?.redirect,
    component: () => import("/home/app/base/pages/upload-document.vue").then(m => m.default || m)
  }
]