import validate from "/home/app/node_modules/nuxt/dist/pages/runtime/validate.js";
import manifest_45route_45rule from "/home/app/node_modules/nuxt/dist/app/middleware/manifest-route-rule.js";
export const globalMiddleware = [
  validate,
  manifest_45route_45rule
]
export const namedMiddleware = {
  "approve-required": () => import("/home/app/base/middleware/approve-required.ts"),
  "sign-up-available": () => import("/home/app/base/middleware/sign-up-available.ts"),
  "sign-up-required": () => import("/home/app/base/middleware/sign-up-required.ts"),
  "sign-up-successful": () => import("/home/app/base/middleware/sign-up-successful.ts"),
  "verification-required": () => import("/home/app/base/middleware/verification-required.ts"),
  "approved-only": () => import("/home/app/agent-snap/middleware/approved-only.ts"),
  authenticated: () => import("/home/app/agent-snap/middleware/authenticated.ts"),
  "business-only": () => import("/home/app/agent-snap/middleware/business-only.ts"),
  "hide-page-for-stripe-account": () => import("/home/app/agent-snap/middleware/hide-page-for-stripe-account.ts"),
  "not-registered-only": () => import("/home/app/agent-snap/middleware/not-registered-only.ts"),
  "stripe-enabled": () => import("/home/app/agent-snap/middleware/stripe-enabled.ts"),
  unauthenticated: () => import("/home/app/agent-snap/middleware/unauthenticated.ts"),
  auth: () => import("/home/app/node_modules/@sidebase/nuxt-auth/dist/runtime/middleware/auth.mjs")
}